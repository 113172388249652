import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { User } from '@src/app/shared/models/user.model';
import { environment } from '@src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, catchError, map, of, throwError } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class UserService {
	static readonly UNKNOWN_USER = new User();
	private userSubject$ = new BehaviorSubject<User>(UserService.UNKNOWN_USER);

	constructor(
		private _http: HttpClient,
		private _toastrService: ToastrService,
	) {}

	getUserInformation() {
		return this._http.get(`${environment.apiUrl}/api/utilisateur/InformationsUtilisateurConnecte`, { withCredentials: true }).pipe(
			map(res => {
				if (res) {
					let userConnected = res as User;
					this.userSubject$.next(userConnected);
					return userConnected;
				} else {
					this.userSubject$.next(UserService.UNKNOWN_USER);
					return UserService.UNKNOWN_USER;
				}
			}),
			catchError(error => {
				if (error.status === 403) {
					this.userSubject$.next(UserService.UNKNOWN_USER);
					return of(UserService.UNKNOWN_USER);
				} else if (error.status === 504) {
					this._toastrService.error('Chargement impossible, vérifiez votre connexion.');
				}

				console.error('Erreur lors de la récupération des informations utilisateur:', error);
				return throwError(() => new Error(error));
			}),
		);
	}

	isUserLoggedIn() {
		return !!this.userSubject$.getValue().id;
	}

	getUser() {
		return this.userSubject$.getValue();
	}

	getUserObservable() {
		return this.userSubject$.asObservable();
	}

	clear() {
		this.userSubject$.next(UserService.UNKNOWN_USER);
	}
}
