import { Component, OnDestroy, OnInit } from '@angular/core';
import { User } from '@src/app/shared/models/user.model';
import { AgencyService } from '@src/app/shared/services/agency.service';
import { UserService } from '@src/app/shared/services/user.service';
import { Subscription } from 'rxjs';

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
	user: User | null = null;
	agencyName: string = '';
	private subscription: Subscription = new Subscription();

	constructor(
		private _userService: UserService,
		private _agencyService: AgencyService,
	) {}

	ngOnInit(): void {
		this.subscription = this._userService.getUserObservable().subscribe(user => {
			if (user.id && this.user?.idAgence != user.idAgence) {
				this.user = user;
				this._agencyService.getAgencyInformations(this.user.idAgence).subscribe(agency => {
					this.agencyName = agency.nom.length > 30 ? agency.nom.slice(0, 30) + '...' : agency.nom;
				});
			}
		});

		this._userService.getUserInformation().subscribe();
	}

	ngOnDestroy() {
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
	}
}
