import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Slot } from '@src/app/shared/models/slot.model';
import { environment } from '@src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class PlanningService {
	constructor(private _http: HttpClient) {}

	public getSlots(idAgency: string): Observable<Array<Slot>> {
		return this._http.get<Array<Slot>>(`${environment.apiUrl}/api/Mobile/AgendaTablette?idAgence=${idAgency}`, {
			withCredentials: true,
		});
	}
}
