import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ObjectLibelle, QuestionsTraining, Response, ResponseTraning } from '@shared/models/follow-up-training.model';
import { environment } from '@src/environments/environment';
import { BehaviorSubject, Observable, forkJoin, tap } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class FollowUpTrainingService {
	private arrayResponses = new BehaviorSubject<Array<Response>>([]);
	data$ = this.arrayResponses.asObservable();
	constructor(private _http: HttpClient) {}

	public getQuestions(): Observable<QuestionsTraining> {
		return this._http.get<QuestionsTraining>(`${environment.apiUrl}/api/Mobile/QuestionsSuiviPedagogique`, {
			withCredentials: true,
		});
	}

	public getResponsesByCategory(idAgence: string, idContrat: string, categorie: string): Observable<Array<ResponseTraning>> {
		return this._http
			.get<Array<ResponseTraning>>(`${environment.apiUrl}/api/Mobile/ReponsesSuiviPedagogique?idAgence=${idAgence}`, {
				withCredentials: true,
			})
			.pipe(
				tap(newData => {
					let selectedContract = newData.find(data => data.idContrat === idContrat);
					if (selectedContract !== undefined && categorie === 'suiviFormation') this.arrayResponses.next(selectedContract.suiviFormation);
					if (selectedContract !== undefined && categorie === 'autoEvaluation') this.arrayResponses.next(selectedContract.autoEvaluation);
				}),
			);
	}

	public getQuestionsAndResponses(idAgence: string, idContrat: string, categorie: string): Observable<Array<any>> {
		return forkJoin([this.getQuestions(), this.getResponsesByCategory(idAgence, idContrat, categorie)]);
	}

	public updateReponses(idContrat: string, responses: Array<ObjectLibelle>): Observable<any> {
		let newResponses = this.buildNewResponsesArray(responses);
		return this._http.post<Array<Response>>(`${environment.apiUrl}/api/Mobile/ModifierSuiviFormation`, {
			withCredentials: true,
			reponses: newResponses,
			idContrat: idContrat,
		});
	}

	private buildNewResponsesArray(newResponses: Array<ObjectLibelle>): Array<Response> {
		let array: Array<Response> = this.arrayResponses.value;
		newResponses?.forEach((response: ObjectLibelle) => {
			let questionFounded = this.arrayResponses.value.find((question: Response) => question.idQuestion === response.id);
			if (questionFounded) {
				questionFounded.valeur = response.value;
			} else {
				array.push({ idQuestion: response.id, valeur: response.value });
			}
		});
		return array;
	}
}
