<div class="pastille-container">
	<div class="circle-dark red-dark" [ngClass]="chips[0].isOn ? 'red-dark' : 'opaque'" (click)="updateScore(0)">
		<div class="circle-light red-light" [ngClass]="{ 'red-light': chips[0].isOn }"></div>
	</div>
	<div class="circle-dark yellow-dark" [ngClass]="chips[1].isOn ? 'yellow-dark' : 'opaque'" (click)="updateScore(1)">
		<div class="circle-light yellow-light"></div>
	</div>
	<div class="circle-dark sandy-dark" [ngClass]="chips[2].isOn ? 'sandy-dark' : 'opaque'" (click)="updateScore(2)">
		<div class="circle-light sandy-light" [ngClass]="{ 'sandy-light': chips[2].isOn }"></div>
	</div>
	<div class="circle-dark green-dark" [ngClass]="chips[3].isOn ? 'green-dark' : 'opaque'" (click)="updateScore(3)">
		<div class="circle-light green-light"></div>
	</div>
</div>
