import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { SharedModule } from '@app/shared/shared.module';
import { DashboardPage } from '@src/app/features/dashboard/page/dashboard.page';

const routes: Routes = [{ path: '', pathMatch: 'full', component: DashboardPage }];

@NgModule({
	declarations: [DashboardPage],
	imports: [CommonModule, SharedModule, RouterModule.forChild(routes)],
})
export class DashboardModule {}
