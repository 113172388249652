import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@src/app/shared/guards/auth.guard';
import { LoginGuard } from '@src/app/shared/guards/login.guard';

export enum RoutingEnum {
	HOME = 'home',
	PLANNING = 'planning',
	ACCOUNT = 'account',
	STUDENT = 'students',
	KILOMETERTRACKING = 'kilometer-tracking',
	EDUCATIONALMONITORING = 'suivi-pedagogique',
	ILLUSTRATION = 'illustrations',
	FORMATION = 'suivi-formation',
}

const routes: Routes = [
	{
		path: 'login',
		loadChildren: () => import('./features/login/login.module').then(m => m.LoginModule),
		canActivate: [LoginGuard],
	},
	{
		path: RoutingEnum.HOME,
		canActivate: [AuthGuard],
		data: { breadcrumb: 'Tableau de bord' },
		children: [
			{
				path: '',
				canActivate: [AuthGuard],
				loadChildren: () => import('./features/dashboard/dashboard.module').then(m => m.DashboardModule),
				data: { breadcrumb: 'Tableau de bord' },
			},
			{
				path: RoutingEnum.PLANNING,
				canActivate: [AuthGuard],
				loadChildren: () => import('./features/planning/planning.module').then(m => m.PlanningModule),
				data: { breadcrumb: 'Planning' },
			},
			{
				path: RoutingEnum.ACCOUNT,
				canActivate: [AuthGuard],
				loadChildren: () => import('./features/user-account/user-account.module').then(m => m.UserAccountModule),
				data: { breadcrumb: 'Mon compte' },
			},
			{
				path: RoutingEnum.STUDENT,
				canActivate: [AuthGuard],
				loadChildren: () => import('./features/students/students.module').then(m => m.StudentsModule),
				data: { breadcrumb: 'Élèves' },
			},
			{
				path: RoutingEnum.KILOMETERTRACKING,
				canActivate: [AuthGuard],
				loadChildren: () => import('./features/kilometer-tracking/kilometer-tracking.module').then(m => m.KilometerTrackingModule),
				data: { breadcrumb: 'Suivi kilométrique' },
			},
		],
	},
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule],
})
export class AppRoutingModule {}
