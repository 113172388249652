import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '@src/app/shared/services/auth.service';
import { UserService } from '@src/app/shared/services/user.service';
import { ToastrService } from 'ngx-toastr';
import { Observable, map } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class AuthGuard implements CanActivate {
	constructor(
		private _userService: UserService,
		private _router: Router,
		private _toastrService: ToastrService,
		private _authService: AuthService,
	) {}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
		if (this._userService.isUserLoggedIn()) {
			if (this._userService.getUser().niveauDroits === 'ELEVE') {
				this._toastrService.error('Accès non autorisé');
				this._authService.logout().subscribe();
				return false;
			}
			return true;
		}

		return this._userService.getUserInformation().pipe(
			map(user => {
				if (this._userService.isUserLoggedIn()) {
					if (this._userService.getUser().niveauDroits === 'ELEVE') {
						this._toastrService.error('Accès non autorisé');
						this._authService.logout().subscribe();
						return false;
					}
					return true;
				} else {
					this._router.navigate(['/login']);
					return false;
				}
			}),
		);
	}
}
