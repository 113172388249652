import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoadingService } from '@src/app/shared/services/loading.service';
import { ToastrService } from 'ngx-toastr';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
	constructor(
		private loadingService: LoadingService,
		private _toastrService: ToastrService,
	) {}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		this.loadingService.startLoading();
		return next.handle(request).pipe(
			catchError((err: HttpErrorResponse) => {
				if (!navigator.onLine) {
					this._toastrService.error('Chargement impossible, vérifiez votre connexion.');
				}
				return throwError(() => err);
			}),
			finalize(() => {
				this.loadingService.stopLoading();
			}),
		);
	}
}
