import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-filled-button',
  templateUrl: './filled-button.component.html',
  styleUrls: ['./filled-button.component.scss']
})
export class FilledButtonComponent {
  @Input() text: string
  @Input() marginVertical: number
  @Input() marginHorizontal: number
  @Input() disabled: boolean
  @Input() pageToRedirect: string | null
  @Input() type!: string

  constructor() {
    this.text = "Bouton"
    this.marginVertical = 20
    this.marginHorizontal = 20
    this.disabled = false
    this.pageToRedirect = null
    this.type = "button"
  }
}
