import { formatDate } from '@angular/common';
import { Injectable } from '@angular/core';
import dateFormat from 'dateformat';

@Injectable({
	providedIn: 'root',
})
export class DateService {
	//return 2019-12-08 12:00
	public convertDateFormat(dateToConvert: string): string {
		const [datePart, timePart] = dateToConvert.split(' ');
		const [day, month, year] = datePart.split('/');
		return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')} ${timePart}`;
	}

	//return "Le 17 Octobre 2023 de 16:00 à 18:00"
	public reformateDate(startDate: string, endDate: string): string {
		const [startDatePart, startTimePart] = startDate.split(' ');
		const [endtDatePart, endtTimePart] = endDate.split(' ');

		const [day, month, year] = startDatePart.split('/').map(Number);
		const formattedDate = new Date(year, month - 1, day);

		const dateString = formatDate(formattedDate, 'd MMMM y', 'fr-FR');

		return `Le ${dateString} de ${startTimePart} à ${endtTimePart}`;
	}

	// Tue Nov 07 2023 00:00:00 GMT+0100 -> 2023-11-07
	public formatDateIsoToYYYYMMDD(date: string): Date {
		return new Date(dateFormat(new Date(date), 'yyyy-mm-dd'));
	}

	public formatDateIsoToDDMMYYYY(date: Date): string {
		return dateFormat(new Date(date), 'dd/mm/yyyy');
	}
}
