import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-card',
	templateUrl: './card.component.html',
	styleUrls: ['./card.component.scss'],
})
export class CardComponent {
	@Input() public text!: string;
	@Input() public img!: string;
	@Input() public color!: string;
	@Input() public shape!: string;
	@Input() public pageToRedirect!: string;
	@Input() public grey: boolean = false;
}
