import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '@src/app/shared/services/user.service';
import { environment } from '@src/environments/environment';
import { BehaviorSubject, Observable, map, tap } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class AuthService {
	public token: BehaviorSubject<string> = new BehaviorSubject<string>('');

	constructor(
		private _http: HttpClient,
		private userService: UserService,
		private router: Router,
	) {}

	login(email: string, password: string): Observable<any> {
		const body = { adresseCourriel: email, motDePasse: password };
		return this._http.post(`${environment.apiUrl}/api/utilisateur/authentifierUtilisateur`, body, { withCredentials: true }).pipe(
			map(() => {
				return true;
			}),
		);
	}

	jeton(): Observable<string> {
		return this._http.get<string>(`${environment.apiUrl}/api/utilisateur/JetonUtilisateur`, { withCredentials: true }).pipe(
			tap(j => {
				this.token.next(j);
			}),
		);
	}

	logout(): Observable<any> {
		return this._http.post(`${environment.apiUrl}/api/utilisateur/Deconnecter`, {}, { withCredentials: true }).pipe(
			tap(() => {
				this.userService.clear();
				this.token.next('');
				this.router.navigateByUrl('/login');
			}),
		);
	}

	passwordForgotten(email: string): Observable<any> {
		const body = { courrielUtilisateur: email, urlMotDePasseOublie: environment.webUrl + '/Authentification/RenouvelerMotDePasse' };
		return this._http.post(`${environment.apiUrl}/api/utilisateur/DemandeMotDePasseOublie`, body, { withCredentials: true });
	}
}
