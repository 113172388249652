<div class="dashboard">
	<div class="strip">
		<h2 *ngIf="user">Bonjour {{ user.prenom }},</h2>
		<h5>Vous avez {{ numberLessons }} cours aujourd'hui.</h5>
		<button mat-flat-button routerLink="/home/planning" [queryParams]="{ initialView: 'dayGrid' }">Voir mon planning</button>
	</div>

	<div class="cards">
		<div class="first_line">
			<app-card
				text="Élèves"
				img="assets/MONITEUR/dashboard/eleves.svg"
				shape="assets/_FORMES/607.svg"
				pageToRedirect="/home/students"></app-card>
			<app-card
				text="Planning"
				img="assets/MONITEUR/dashboard/planning.svg"
				shape="assets/_FORMES/695.svg"
				pageToRedirect="/home/planning"></app-card>
		</div>
		<div class="second_line">
			<app-card
				text="Mon compte"
				img="assets/MONITEUR/dashboard/compte.svg"
				shape="assets/_FORMES/1686.svg"
				pageToRedirect="/home/account"></app-card>
			<app-card
				text="Suivi kilométrique"
				img="assets/MONITEUR/dashboard/suivi_kilometrique.svg"
				shape="assets/_FORMES/545.svg"
				pageToRedirect="/home/kilometer-tracking"></app-card>
		</div>
	</div>
</div>
