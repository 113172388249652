import { Location } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FollowUpTrainingService } from '@features/follow-up-training/follow-up-training.service';
import { Category, Competence, ObjectLibelle } from '@shared/models/follow-up-training.model';
import { Icon } from '@src/app/shared/models/icon.model';
import { LicenseType } from '@shared/models/license-type.enum';

@Component({
	selector: 'app-training-card-details',
	templateUrl: './training-card-details.component.html',
	styleUrls: ['./training-card-details.component.scss'],
})
export class TrainingCardDetailsComponent implements OnInit{
	@Input() public competence!: Competence;
	@Input() public selectedCard!: Category;

	public iconsArray: Array<Icon> = [];
  private licenseType!: LicenseType | undefined;

	constructor(
		private router: Router,
		private _futService: FollowUpTrainingService,
		private location: Location,
		private activatedRoute: ActivatedRoute,
	) {}

  ngOnInit(): void {
    this.licenseType = this.activatedRoute.snapshot.params['permis'];
  }

	public updateScore(event: Competence | ObjectLibelle): void {
    if (this.licenseType !== 'REMORQUE') {
      this.competence.notions.forEach((notion: ObjectLibelle) => {
        notion.value = event.value;
      });
      this._futService.updateReponses(this.activatedRoute.snapshot.params['contrat'], this.competence.notions).subscribe();
    } else {
      if (typeof event.value === 'number')
        this.competence.value = event.value
      this._futService.updateReponses(this.activatedRoute.snapshot.params['contrat'], [this.competence]).subscribe();
    }


	}

	public navigateTo(): void {
    if (this.licenseType !== 'REMORQUE')
      this.router.navigate([this.location.path(), this.selectedCard.libelle.substring(0, 2)], {
        state: { selectedCard: this.selectedCard },
      });
	}
}
