import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Color } from '@src/app/shared/enum/color.enum';
import { Competence, ObjectLibelle } from '@src/app/shared/models/follow-up-training.model';
import { Icon } from '@src/app/shared/models/icon.model';

@Component({
	selector: 'app-colored-icons',
	templateUrl: './colored-icons.component.html',
	styleUrls: ['./colored-icons.component.scss'],
})
export class ColoredIconsComponent implements OnInit {
	@Output() event = new EventEmitter<ObjectLibelle | Competence>();
	@Input() notion!: ObjectLibelle | Competence;

	public chips: Array<Icon> = [];

	ngOnInit(): void {
		this.initializeArray();
		this.chips = this.getChipsArray(this.notion);
	}

	public getChipsArray(notion: ObjectLibelle): Array<Icon> {
		const newChipsArray = this.chips.map((icon: Icon) => {
			if (icon.value === notion.value) {
				return { ...icon, isOn: true };
			}
			return icon;
		});
		return newChipsArray;
	}

	private initializeArray() {
		this.chips = [
			{ color: Color.RED, value: 0, isOn: false },
			{ color: Color.YELLOW, value: 1, isOn: false },
			{ color: Color.ORANGE, value: 2, isOn: false },
			{ color: Color.GREEN, value: 3, isOn: false },
		];
	}

	public updateScore(value: number): void {
		this.notion.value = value;
		this.initializeArray();
		this.chips = this.getChipsArray(this.notion);
		this.event.emit(this.notion);
	}
}
