import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
  name: 'capitalizeFirstLetter'
})
export class CapitalizeFirstLetter implements PipeTransform {
  transform(value: string | null): string | null {
    if (!value) return value;
    return value.charAt(0).toUpperCase() + value.slice(1);
  }
}
