import { Component } from '@angular/core';
import { LoadingService } from '@src/app/shared/services/loading.service';

@Component({
	selector: 'app-spinner',
	templateUrl: './spinner.component.html',
	styleUrls: ['./spinner.component.scss'],
})
export class SpinnerComponent {
	loading$ = this.loadingService.isLoading$;

	constructor(public loadingService: LoadingService) {}
}
