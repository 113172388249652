import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { PlanningService } from '@src/app/features/planning/planning.service';
import { Slot } from '@src/app/shared/models/slot.model';
import { User } from '@src/app/shared/models/user.model';
import { DateService } from '@src/app/shared/services/date.service';
import { UserService } from '@src/app/shared/services/user.service';
import { Subscription } from 'rxjs';

@Component({
	templateUrl: './dashboard.page.html',
	styleUrls: ['./dashboard.page.scss'],
})
export class DashboardPage implements OnInit, OnDestroy {
	public numberLessons!: number;

	user: User | null = null;
	private subscription: Subscription = new Subscription();

	constructor(
		private _userService: UserService,
		private _planningService: PlanningService,
		private _dateService: DateService,
		private router: Router,
		private _location: Location,
	) {}

	ngOnInit(): void {
		this.subscription = this._userService.getUserObservable().subscribe(user => {
			if (user.id && this.user?.idAgence != user.idAgence) {
				this.user = user;
				this._planningService.getSlots(user.idAgence).subscribe(slots => {
					this.numberLessons = slots.filter(
						(event: Slot) =>
							event.dateHeureDebut.substring(0, 10) == this._dateService.formatDateIsoToDDMMYYYY(new Date()) &&
							event.idColonne == user.id &&
							event.typeEvenement == 'RENDEZ_VOUS',
					).length;
				});
			} else {
				this.numberLessons = 0;
			}
		});

		this._userService.getUserInformation().subscribe(user => {
			//patch to redirect to /login page when user no connected (guard currently not working for root path)
			if (this._location.path() == '' && !user.id) {
				this.router.navigateByUrl('/login');
			}
		});
	}

	ngOnDestroy() {
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
	}
}
