import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { UserService } from '@src/app/shared/services/user.service';
import { Observable, map } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class LoginGuard implements CanActivate {
	constructor(
		private _userService: UserService,
		private _router: Router,
	) {}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
		if (this._userService.isUserLoggedIn()) {
			this._router.navigate(['/home']);
			return false;
		}

		return this._userService.getUserInformation().pipe(
			map(user => {
				if (this._userService.isUserLoggedIn()) {
					this._router.navigate(['/home']);
					return false;
				} else {
					return true;
				}
			}),
		);
	}
}
