import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import localeFr from '@angular/common/locales/fr';
import { LOCALE_ID, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';

import { AppRoutingModule } from '@app/app-routing.module';
import { AppComponent } from '@app/app.component';
import { DashboardModule } from '@app/features/dashboard/dashboard.module';
import { SharedModule } from '@app/shared/shared.module';
import { TokenInterceptor } from '@shared/interceptors/token.interceptor';
import { AuthGuard } from '@src/app/shared/guards/auth.guard';
import { LoginGuard } from '@src/app/shared/guards/login.guard';
import { LoadingInterceptor } from '@src/app/shared/interceptors/loading.interceptor';
import { environment } from '@src/environments/environment';

registerLocaleData(localeFr);

@NgModule({
	declarations: [AppComponent],
	imports: [
		BrowserModule,
		ReactiveFormsModule,
		HttpClientModule,
		SharedModule,
		ServiceWorkerModule.register('ngsw-worker.js', {
			enabled: environment.enable_service_worker,
			registrationStrategy: 'registerImmediately',
		}),
		BrowserAnimationsModule,
		DashboardModule,
		AppRoutingModule,
	],
	providers: [
		AuthGuard,
		LoginGuard,
		{ provide: LOCALE_ID, useValue: 'fr-FR' },
		{ provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
