<mat-toolbar color="primary">
	<mat-toolbar-row class="header">
		<img src="assets/logo_blanc.svg" alt="logo-cityzen" class="logo" [routerLink]="['/']" />
		<div class="informations mobile_view">
			<span class="name"> {{ user?.prenom }} </span>
			<p class="agency">{{ agencyName }}</p>
		</div>
		<div class="tablet_view name" [ngClass]="{ marginAll: agencyName }">
			<img src="assets/icons/avatar_new.png" alt="" class="tablet_view" width="30px" height="30px" />
			<span class="tablet_view name"> {{ user?.prenom }} </span>
		</div>
		<p class="tablet_view agency">{{ agencyName }}</p>
	</mat-toolbar-row>
</mat-toolbar>
