import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '@app/material/material.module';
import { FullCalendarModule } from '@fullcalendar/angular';
import { CapitalizeFirstLetter } from '@shared/pipe/capitalizeFirstLetter.pipe';
import { CardComponent } from '@src/app/shared/component/card/card.component';
import { ColoredIconsComponent } from '@src/app/shared/component/colored-icons/colored-icons.component';
import { FilledButtonComponent } from '@src/app/shared/component/filled-button/filled-button.component';
import { PageTitleComponent } from '@src/app/shared/component/page-title/page-title.component';
import { SpinnerComponent } from '@src/app/shared/component/spinner/spinner.component';
import { TrainingCardDetailsComponent } from '@src/app/shared/component/training-card-details/training-card-details.component';
import { BoldTextPipe } from '@src/app/shared/pipe/boldText.pipe';
import { ShortenPipe } from '@src/app/shared/pipe/shorten.pipe';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { ToastrModule } from 'ngx-toastr';
import { BreadcrumbModule } from 'xng-breadcrumb';
import { HeaderComponent } from './component/header/header.component';

@NgModule({
	declarations: [
		FilledButtonComponent,
		CardComponent,
		TrainingCardDetailsComponent,
		ColoredIconsComponent,
		PageTitleComponent,
		BoldTextPipe,
		CapitalizeFirstLetter,
		HeaderComponent,
		ShortenPipe,
		SpinnerComponent,
	],
	imports: [
		CommonModule,
		MaterialModule,
		ToastrModule.forRoot({
			positionClass: 'toast-bottom-right',
		}),
		ReactiveFormsModule,
		RouterModule,
		BreadcrumbModule,
		FullCalendarModule,
		PdfViewerModule,
	],
	exports: [
		MaterialModule,
		FilledButtonComponent,
		CapitalizeFirstLetter,
		CardComponent,
		ShortenPipe,
		TrainingCardDetailsComponent,
		ColoredIconsComponent,
		PageTitleComponent,
		ReactiveFormsModule,
		RouterModule,
		BreadcrumbModule,
		FullCalendarModule,
		HeaderComponent,
		PdfViewerModule,
		SpinnerComponent,
	],
	providers: [DatePipe],
})
export class SharedModule {}
