import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@shared/services/auth.service';
import { environment } from '@src/environments/environment';
import { Observable, switchMap } from 'rxjs';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
	constructor(private authService: AuthService) {}

	private excludePatterns = [new RegExp(`${environment.apiUrl}/api/utilisateur/.*`)];

	intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		if (this.isExcluded(request.url)) {
			return next.handle(request);
		}

		let token: string = this.authService.token.getValue();

		// retrieve token from backend api if not set
		if (token == '') {
			return this.authService.jeton().pipe(
				switchMap(j => {
					return next.handle(this.setCisJeton(request, j));
				}),
			);
		} else {
			return next.handle(this.setCisJeton(request, token));
		}
	}

	setCisJeton(request: HttpRequest<unknown>, token: string) {
		request = request.clone({
			setHeaders: {
				cis_jeton: `${token}`,
			},
		});

		return request;
	}

	private isExcluded(url: string): boolean {
		return this.excludePatterns.some(pattern => pattern.test(url));
	}
}
