import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';

import { Agency, AgencyForUser } from '@src/app/shared/models/agency.model';
import { User } from '@src/app/shared/models/user.model';
import { UserService } from '@src/app/shared/services/user.service';
import { environment } from '@src/environments/environment';
import { ToastrService } from 'ngx-toastr';

@Injectable({
	providedIn: 'root',
})
export class AgencyService {
	constructor(
		private _http: HttpClient,
		private _userService: UserService,
		private _toastrService: ToastrService,
	) {
		this.updateConnectedUser();
	}

	public getAgencies(): Observable<Array<Agency>> {
		return this._http.get<Array<Agency>>(`${environment.apiUrl}/api/Agence/Toutes`, {
			withCredentials: true,
		});
	}

	public getAgenciesOfUser(): Observable<Array<AgencyForUser>> {
		return this._http.get<Array<AgencyForUser>>(`${environment.apiUrl}/api/utilisateur/ChoixAgencesUtilisateur`, {
			withCredentials: true,
		});
	}

	public getAgencyInformations(idAgence: string): Observable<any> {
		return this._http.get<any>(`${environment.apiUrl}/api/Agence/Informations?idAgence=${idAgence}`, {
			withCredentials: true,
		});
	}

	public changeAgency(id: string): void {
		const body = { idAgence: id };
		this._http.post(`${environment.apiUrl}/api/utilisateur/ChangerAgence`, body, { withCredentials: true }).subscribe({
			complete: () => {
				this._toastrService.success('Changement agence effectué');
				this.updateConnectedUser();
			},
		});
	}

	public getAgencySelectedId(): Observable<string> {
		return this._userService.getUserInformation().pipe(map((user: User) => user.idAgence));
	}

	private updateConnectedUser() {
		this._userService.getUserInformation().subscribe();
	}
}
